import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img src="/assets/alchemist-logo.svg" alt="Alchemist Logo" />
        </div>
        <div className="footer-links">
          {/* <a href="/privacy-policy">Privacy Policy</a>
          <a href="/terms-of-service">Terms of Service</a>
          <a href="/sitemap.xml">Sitemap</a> */}
        </div>
        <div className="footer-copyright">
          © 2024 The Alchemist. All rights reserved.
        </div>
      </div>
      <div className="footer-background"></div>
    </footer>
  );
}

export default Footer;