import React from 'react';
import './Features.css';
import { logEvent } from '../utils/analytics';

function Features() {
  const handleEnrollClick = () => {
    logEvent('Features', 'Click', 'Enroll');
    window.location.href = 'https://alchemist.care/';
  };

  const handleSignUpClick = () => {
    logEvent('Features', 'Click', 'Sign Up Today');
    window.location.href = 'https://alchemist.care/';
  };

  return (
    <section className="features">
      <div className="features-main">
        <img src="/assets/Whimsical-Overcast-Lands.png" alt="Whimsical Overcast Lands" className="features-image" />
        <div className="features-content">
          <h2>Transform Money Worries into Confidence</h2>
          <p>Experience a data-driven approach to financial education that empowers you to achieve lasting financial success. Our high-quality course methodology combines behavioral science and practical knowledge to guide you towards financial well-being.</p>
        </div>
      </div>
      <div className="features-grid">
        <div className="feature-item">
          <h3>Take Control of your Financials</h3>
          <p>Our data-driven course empowers individuals to take control of their finances with a high-quality learning experience that includes behavioral finance principles.</p>
        </div>
        <div className="feature-item">
          <h3>Master Financial Skills with Confidence</h3>
          <p>Gain practical knowledge and build confidence in managing your finances, we aim to empower individuals in improving their financial well-being.</p>
        </div>
        <div className="feature-item">
          <h3>Personalized Learning for Lasting Results</h3>
          <p>Embark on a journey to understand your financial habits and achieve lasting changes in how you manage your money.</p>
        </div>
        <button className="enroll-button" onClick={handleEnrollClick}>Enroll</button>
      </div>
      <div className="features-paycheque">
        <div className="paycheque-content">
          <h2>Stop living paycheque to paycheque</h2>
          <p>With The Alchemist, we utilize established therapeutic techniques such as exposure therapy to equip you with the necessary tools for creating lasting changes. Our course is designed to ensure that you are not overwhelmed by immediate financial demands. Instead, it is structured to prepare you for managing the next steps effectively. This approach is aimed at fostering enduring transformation in money management.</p>
        </div>
        <img src="/assets/Evil-overdue-bills.png" alt="Evil overdue bills" className="paycheque-image" />
      </div>
      
      <div className="features-money-smarts">
        <h2>Build Money Smarts and Feel Good About Your Finances</h2>
        <div className="money-smarts-grid">
          <div className="money-smart-item">
            <img src="/assets/Silhouette-start.png" alt="Whimsical jar 1" className="money-smart-image silhouette-start" />
            <h3>Get a Handle on Your Spending</h3>
            <p>Ever feel like your money disappears? Let's change that! Our step-by-step guides help you track where your cash goes, so you can take charge of your finances. It's like having a money coach in your pocket!</p>
          </div>
          <div className="money-smart-item">
            <img src="/assets/Watering-Money-Tree.png" alt="Whimsical jar 2" className="money-smart-image" />
            <h3>Build Your Financial Freedom</h3>
            <p>Ready to break free from money stress? Join our community of go-getters who are creating lasting wealth with The Alchemist. We'll show you how to grow your money and reach those big dreams!</p>
          </div>
          <div className="money-smart-item">
            <img src="/assets/Golden-Path-Simplified.png" alt="Whimsical jar 3" className="money-smart-image" />
            <h3>Money Smarts, Less Stress</h3>
            <p>Imagine feeling calm about your finances. Our lessons help you develop smart money habits that cut down on stress and bring clarity to your financial life. It's time to feel good about your money!</p>
          </div>
        </div>
        <button className="signup-button" onClick={handleSignUpClick}>Sign Up Today</button>
      </div>
    </section>
  );
}

export default Features;