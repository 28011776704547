import React, { useState } from 'react';
import './Header.css';
import { logEvent } from '../utils/analytics';

function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleLoginClick = () => {
        logEvent('Header', 'Click', 'Login');
    };

    const handleSignUpClick = () => {
        logEvent('Header', 'Click', 'Sign Up');
    };

    return (
        <header className={`header ${isMenuOpen ? 'menu-open' : ''}`}>
            <div className="container">
                <h1 className="logo">
                    <img src="/assets/alchemist-logo.svg" alt="The Alchemist - Financial Wellbeing" />
                </h1>
                <nav className={`nav ${isMenuOpen ? 'open' : ''}`}>
                    <ul>
                        <li><a href="/" className="active">Home</a></li>
                        <li><a href="mailto:sales@alchemist.care" onClick={() => logEvent('Header', 'Click', 'Talk to Sales')}>Talk to Sales</a></li>
                    </ul>
                    <div className="mobile-auth-buttons">
                        <a href="https://alchemist.care/login" className="login-btn" onClick={handleLoginClick}>Log in</a>
                        <a href="https://alchemist.care/" className="signup-btn" onClick={handleSignUpClick}>Sign Up</a>
                    </div>
                </nav>
                <div className="desktop-auth-buttons">
                    <a href="https://alchemist.care/login" className="login-btn" onClick={handleLoginClick}>Log in</a>
                    <a href="https://alchemist.care/" className="signup-btn" onClick={handleSignUpClick}>Sign Up</a>
                </div>
                <button className="menu-toggle" onClick={toggleMenu} aria-label="Toggle menu">
                    ☰
                </button>
            </div>
        </header>
    );
}

export default Header;