import React, { useState, useEffect } from 'react';
import './Hero.css'; // We'll create this file for styling
import GradualSpacing from '../components/magicui/gradual-spacing';
import ShinyButton from '../components/magicui/shiny-button';
import { logEvent } from '../utils/analytics';

// Custom hook for window size
function useWindowSize() {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
  useEffect(() => {
    const handleResize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return size;
}

function Hero() {
  const [width] = useWindowSize();
  const breakpoint = 768; // Adjust this value as needed

  const handleSignUpClick = () => {
    logEvent('Hero', 'Click', 'Sign Up Today');
  };

  return (
    <section className="hero">
      <div className="hero-content">
        <h1 className="hero-title">
          {width > breakpoint ? (
            <>
              <GradualSpacing
                text="Start Your Money Journey:"
                duration={0.5}
                delayMultiple={0.04}
                className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-[#059669]"
                framerProps={{
                  hidden: { opacity: 0, y: 20 },
                  visible: { opacity: 1, y: 0 },
                }}
              />
              <GradualSpacing
                text="Learn to Love Your Finances"
                duration={0.5}
                delayMultiple={0.04}
                className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-[#059669]"
                framerProps={{
                  hidden: { opacity: 0, y: 20 },
                  visible: { opacity: 1, y: 0 },
                }}
              />
            </>
          ) : (
            <span className=" font-bold text-[#059669]">
              Start Your Money Journey:<br />
              Learn to Love Your Finances
            </span>
          )}
        </h1>
        <p className="hero-description">
          Embark on a transformative journey with The Alchemist, where behavioral insights meet financial mastery to build lasting habits and reduce stress.
        </p>
        <ShinyButton
          text="Sign Up Today"
          className="hero-button"
          onClick={() => {
            handleSignUpClick();
            window.location.href = 'https://alchemist.care/';
          }}
        />
      </div>
      <div className="hero-image"></div>
    </section>
  );
}

export default Hero;