import React, { useEffect } from 'react';
import { initGA, logPageView } from './utils/analytics';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import MainCTA from './components/MainCTA';
import Footer from './components/Footer';

function App() {
    useEffect(() => {
        initGA('G-YSLLTZNKPG'); // Replace with your Measurement ID
        logPageView();
    }, []);

    return (
        <div className="App">
            <Header />
            <div className="main-container">
                <Hero />
                <Features />
                <MainCTA />
                <Footer />
            </div>
        </div>
    );
}

export default App;