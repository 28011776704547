import React from 'react';
import './MainCTA.css';
import { logEvent } from '../utils/analytics';

function MainCTA() {
  const handleSignUpClick = () => {
    logEvent('MainCTA', 'Click', 'Sign Up');
    window.location.href = 'https://alchemist.care/';
  };

  const handleTalkToSalesClick = () => {
    logEvent('MainCTA', 'Click', 'Talk to Sales');
    window.location.href = 'mailto:sales@alchemist.care';
  };

  return (
    <section className="main-cta">
      <div className="cta-content">
        <h2>Transform Your Future</h2>
        <p>Discover the path to financial wellness and gain control over your money.</p>
      </div>
      <div className="cta-buttons">
        <button className="cta-button signup" onClick={handleSignUpClick}>Sign Up</button>
        <button className="cta-button talk-to-sales" onClick={handleTalkToSalesClick}>Talk to Sales</button>
      </div>
    </section>
  );
}

export default MainCTA;